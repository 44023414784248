import * as user from '../actions/user.actions';

export interface UserState {
    data: any;
}

export const initialState: UserState = {
    data: {}
};

export function reducer(
    state = initialState,
    action: user.ActionsUnion
): any {

    // this.global.logger('Reducer state', state)
    // this.global.logger('Reducer action', action)
    switch (action.type) {
        case user.ActionTypes.login: {
            // this.global.logger('Reducer Login', action.payload)
            return {
                ...state,
                data: [action.payload]
            };
        }

        case user.ActionTypes.logout: {
            // this.global.logger('Reducer Logout', action.payload)

            return {
                ...state,
                ...state.data.splice(state.data.indexOf(action.payload), 1)
            };
        }

        default: {
            // this.global.logger('Reducer', 'Type = null')
            return state;
        }
    }
}

export const getUser = (state: any) => state.data;