<ion-app>
  <ion-split-pane contentId="main-content" *ngIf="user">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ng-container>
          <div class="logo-image">
          </div>
          <div style="position: relative; text-align: -webkit-center; top: -2%">
            <div
              id="user-bubble"
              style="border: 2px solid white; border-radius: 50%; width: 10vh; height: 10vh; line-height: 10vh; vertical-align: middle; text-align: center; font-size: xx-large; border-color: white; color: #fff; background-color: #aa4644"
            >
              {{ userBubble() }}
            </div>
            {{ currentUserBalance }}
          </div>
          <ion-card class="menu-card">
            <ion-card-content>
              <ion-list id="inbox-list">
                <ion-list-header>Labmin Online Training Platform</ion-list-header>
                <ion-note>{{ user.name }} {{ user.surname }}</ion-note>
                <ion-menu-toggle auto-hide="false">
                  <ng-container *ngFor="let p of appPages; let i = index">
                    <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" [class.selected]="checkUrl(p.url)">
                      <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                      <ion-label>{{ p.title }}</ion-label>
                    </ion-item>
                  </ng-container>
                  <ion-item (click)="logout()" lines="none">
                    <ion-icon slot="start" ios="exit-outline" md="exit-sharp"></ion-icon>
                    <ion-label>Log Out</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ng-container>
      </ion-content>

      <ion-footer color="primary">
        <ion-toolbar style="text-align: center">
          <ion-label style="font-size: xx-small">Version {{ versionCode }}</ion-label
          ><br />
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <ng-container *ngIf="!user">
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ng-container>
</ion-app>