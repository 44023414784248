import { SetQuiz, RemoveQuiz } from './quiz.actions';
import { Action, State, StateContext } from '@ngxs/store';

import { Injectable } from '@angular/core';

import { IQuiz } from './quiz.model';
// ------
@State<IQuiz>({
  name: 'quiz',
  defaults: null
})
@Injectable()
export class QuizStore {

  @Action(SetQuiz)
  setQuiz(
    { setState }: StateContext<IQuiz>,
    { payload }: SetQuiz
  ): void {
    // console.log('Actions defined', payload);
    setState(payload);
  }

  @Action(RemoveQuiz)
  removeQuiz(
    { setState }: StateContext<IQuiz>
  ): void {
    setState(null);
  }
}
