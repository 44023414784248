import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class GlobalFunctionsService {
  noInAppPurchase = false;

  debugging = false;
  loader;

  imageURL = 'https://admin.michemdynamics.co.za/';
  // /src/assets/logos/michem_dynamics_white_default.png"
  defaultImage = '/src/assets/logos/michem_dynamics_white_default.png';
  // src/assets/logos/michem_dynamics_logo_white.png
  constructor(private router: Router, public toastController: ToastController, public loadingController: LoadingController) {}

  logger(title: any = 'No Title', message: any = 'No Message', type = 'log') {
    if (this.debugging == true) {
      switch (type) {
        case 'log':
          console.log(title, message);
          break;
        case 'table':
          console.table(message);
          break;
      }
    }
  }

  async notify(text: any, seconds: number = 2, toastPosition: any = 'bottom', color = 'dark') {
    const toast = await this.toastController.create({
      message: text,
      duration: seconds * 1000,
      position: toastPosition,
      color: color,
    });
    toast.present();
  }

  async doSwal(title: string, text: string, type: any) {
    Swal.fire(title, text, type);
  }

  async load(text: any = 'Please wait', seconds: number = 5) {
    this.loader = await this.loadingController.create({
      message: text,
      duration: seconds * 1000,
    });
    await this.loader.present();
  }

  async startLoader(text: any = 'Please wait') {
    this.logger('Starting Loader');
    this.loader = await this.loadingController.create({
      message: text,
    });
    await this.loader.present();
  }

  endLoader() {
    if (this.loader) {
      this.loader.dismiss();
    }
    if (this.debugging) {
      this.logger('Ending Loader');
    }
  }

  /**
   * Determine whether a variable is considered to be empty.
   * A variable is considered empty if it does not exist or if its value equals FALSE
   */
  isEmpty(isEmptyItem) {
    if (isEmptyItem === undefined || isEmptyItem === false || isEmptyItem === null) {
      return true;
    } else {
      return false;
    }
  }

  getDefaultImage() {
    return this.defaultImage;
  }

  getImageUrl() {
    return this.imageURL;
  }

  ampersandPurifier(stringText: string) {
    console.log(stringText);

    const searchRegExp = /&amp;/gi;
    const replaceWith = '&';

    const result = stringText.replace(searchRegExp, replaceWith);

    console.log(result);
  }
}
