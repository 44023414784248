

// export enum ActionTypes {
//   ADD_USER = '[USER] Add',
//   REMOVE_USER = '[USER] Remove'
// }

export class SetUser {
  static readonly type = '[USER] Add User';
  constructor(public payload: any ) {
  }
}

export class RemoveUser {
  static readonly type = '[USER] Remove User';
  constructor(public payload: any) {}
}
