import { GlobalFunctionsService } from './global-functions.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import sha256, { Hash, HMAC } from 'fast-sha256';
import sha256 from 'crypto-js/sha256';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DsiBackendService {
  walletToken = '';
  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private global: GlobalFunctionsService) {}

  setToken(token) {
    this.walletToken = token;
  }

  async getWalletApiCallTransaction(action, formvars) {
    return new Promise(async (resolve, reject) => {
      // this.global.logger('Starting wallet call');
      await this.global.startLoader();
      const api_url = 'https://vams.michemdynamics.co.za/91TRANSACTION/request/';
      const timeStamp = this.datePipe.transform(new Date(), 'yyyy-MM-dd H:MM:SS');
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('timestamp', timeStamp);
      if (this.walletToken != '') {
        headers = headers.set('token', this.walletToken);
      }
      const body = `{ "user":"dream", "password":"state", "action":"${action}", "system":"LIVE", "formvars": ${formvars} }`;
      let result = null;
      this.global.logger('body', body);
      this.global.logger('url', api_url);
      await this.httpClient.post<any[]>(`${api_url}`, body, { headers }).subscribe(async (data: any) => {
        this.global.logger('Wallet Data', data);
        if (data.error == 1) {
          this.global.notify('Error connecting to server');
        }
        await this.global.endLoader();
        result = data;
        resolve(result);
      });
    });
  }

  async getWalletApiCall(action, formvars) {
    return new Promise(async (resolve, reject) => {
      await this.global.startLoader();
      const apiUrl = 'https://vams.michemdynamics.co.za/90WALLET/request/';
      const timeStamp = this.datePipe.transform(new Date(), 'yyyy-MM-dd H:MM:SS');
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('timestamp', timeStamp);
      if (this.walletToken != '') {
        headers = headers.set('token', this.walletToken);
      }
      const body = `{ "user":"dream", "password":"state", "action":"${action}", "system":"LIVE", "formvars": ${formvars} }`;

      let result = null;
      await this.httpClient.post<any[]>(`${apiUrl}`, body, { headers }).subscribe(async (data: any) => {
        this.global.logger('Wallet Data', data);

        if (data.error == 1) {
          this.global.notify('Error connecting to server');
        }
        await this.global.endLoader();
        result = data;
        resolve(result);
      });
    });
  }

  async getAdminTableRow(table = 'courses', id = '0') {
    return new Promise(async (resolve, reject) => {
      this.global.startLoader();
      const apiUrl = `https://admin.michemdynamics.co.za/webservice/REST/${table}/${id}/`;
      const timeStamp = this.datePipe.transform(new Date(), 'yyyyMMddHMMSS');
      const msg = `dream:state:${timeStamp}`;
      const sha = sha256(msg);
      const token = btoa(sha.toString());
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('timestamp', timeStamp);
      headers = headers.set('token', token);
      headers = headers.set('clientID', '1');
      headers = headers.set('key', '');
      const body = '';
      let result = null;
      await this.httpClient.get<any[]>(`${apiUrl}`, { headers }).subscribe(async (data: any) => {
        this.global.logger('Wallet Data', data);
        if (data.error == 1) {
          this.global.notify('Error connecting to server');
        }
        await this.global.endLoader();
        result = data;
        resolve(result);
      });
    });
  }

  async getAdminFunction(classCall, functionCall, body, id = 0, loading = true) {
    return new Promise(async (resolve, reject) => {
      if (loading == true) {
        this.global.startLoader();
      }
      // https://admin.michemdynamics.co.za/webservice/REST/Chats/0/runAction
      const api_url = `https://admin.michemdynamics.co.za/webservice/REST/${classCall}/${id}/${functionCall}/`;
      const timeStamp = this.datePipe.transform(new Date(), 'yyyyMMddHMMSS');
      const msg = `dream:state:${timeStamp}`;
      const sha = sha256(msg);
      let token = btoa(sha.toString());
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('timestamp', timeStamp);
      headers = headers.set('token', token);
      headers = headers.set('clientID', '1');
      headers = headers.set('key', '');
      let result = null;
      this.global.logger('URL', api_url);
      this.global.logger('Data', body);
      await this.httpClient.post<any[]>(`${api_url}`, body, { headers }).subscribe(async (data: any) => {
        // this.global.logger('Wallet Data', data)
        if (data.error == 1) {
          this.global.notify('Error connecting to server');
        }
        if (this.global.debugging) {
          this.global.logger('Ending Loader');
        }
        // this.global.notify('Ending loader');
        if (loading == true) {
          await this.global.endLoader();
        }
        result = data;
        resolve(result);
      });
    });
  }
}
