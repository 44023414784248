import { Action } from "@ngrx/store";

export enum ActionTypes {
  login = "[Notes Service] Create note",
  logout = "[Notes Service] Delete note"
}

export class Login implements Action {
  readonly type = ActionTypes.login;
  constructor(public payload: { user: any }) {}
}

export class Logout implements Action {
  readonly type = ActionTypes.logout;

  constructor(public payload: { user: any }) {}
}

export type ActionsUnion = Login | Logout;