import { GlobalFunctionsService } from './global-functions.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  public userDetails = {}
  public loggedIn = false;
  constructor(
    private global : GlobalFunctionsService
  ) { }

  setUserDetails(user){
    // this.global.logger('User',user)
    this.loggedIn = true;
    this.userDetails = user;
  }

  getUserDetails(){
    return this.userDetails;
  }
}
