import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/auth/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/auth/reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'courses/:name',
    loadChildren: () => import('./pages/courses/children/course/course.module').then(m => m.CoursePageModule)
  },
  {
    path: 'courses/:name/lesson',
    loadChildren: () => import('./pages/courses/children/lessons/lessons.module').then(m => m.LessonsPageModule)
  },
  {
    path: 'quiz-details',
    loadChildren: () => import('./pages/quiz-details/quiz-details.module').then( m => m.QuizDetailsPageModule)
  },
  {
    path: 'balances',
    loadChildren: () => import('./pages/balances/balances.module').then( m => m.BalancesPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/profile/children/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'buy-course/:name',
    loadChildren: () => import('./pages/home/children/buy-course/buy-course.module').then( m => m.BuyCoursePageModule)
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./pages/profile/children/account-settings/account-settings.module').then( m => m.AccountSettingsPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/auth/login/children/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'course-summary',
    loadChildren: () => import('./pages/courses/children/course-summary/course-summary.module').then(m => m.CourseSummaryPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
