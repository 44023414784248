import { SetUser, RemoveUser } from './user.actions';
import { Action, State, StateContext } from '@ngxs/store';

import { Injectable } from '@angular/core';

// ------
@State<any>({
  name: 'user',
  defaults: null
})
@Injectable()
export class UserStore {

  @Action(SetUser)
  setUser(
    { setState }: StateContext<any>,
    { payload }: SetUser
  ): void {
    // console.log('Actions defined', payload);
    setState(payload);
  }

  @Action(RemoveUser)
  removeUser(
    { setState }: StateContext<any>
  ): void {
    setState(null);
  }
}
