import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from "@ngrx/store";
import { environment } from "../../environments/environment";

import * as user from "./user.reducers";

export interface AppState {
    user: user.UserState;
}

export const reducers: ActionReducerMap<AppState> = {
    user: user.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? []
    : [];

export const getUserState = (state: AppState) => state.user;

export const getUserInfo = createSelector(
    getUserState,
    user.getUser
);