import { SetUser } from './../store/user/user.actions';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as QuizActions from '../store/quiz/quiz.actions';


import { Observable } from 'rxjs';


import * as UserActions from '../actions/user.actions';
import { AppState, getUserInfo } from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: Observable<any>;

  constructor(
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(s => s.user);
  }

  getUser() {
    return this.store.selectSnapshot(s => s.user);
  }

  login(dataa: any): void {
    this.store.dispatch(new SetUser(dataa));
  }
}