import { PayPal } from '@ionic-native/paypal/ngx';
import { environment } from './../environments/environment.prod';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { reducers, metaReducers } from './reducers/index';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IonicStorageModule } from '@ionic/storage';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxsModule } from '@ngxs/store';
import { store } from './store/app-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        NgxPayPalModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        IonicStorageModule.forRoot(),
        NgxsModule.forRoot(store, {
            developmentMode: !environment.production
        }),
        NgxsStoragePluginModule.forRoot()
    ],
    providers: [
        StatusBar,
        SplashScreen,
        DatePipe,
        InAppBrowser,
        PayPal,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
