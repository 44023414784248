import { Logout } from './actions/user.actions';
import { DsiBackendService } from './services/dsiBackend.service';
import { GlobalFunctionsService } from './services/global-functions.service';
import { UserService } from './services/user.service';
import { DataServiceService } from './services/dataService.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute } from '@angular/router';

import { MenuController } from '@ionic/angular';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('user-bubble') userBubbleIcon: ElementRef;

  versionCode = '1.0.14a';
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
    },
    {
      title: 'My Courses',
      url: '/courses',
      icon: 'book',
    },
    {
      title: 'Profile',
      url: '/profile',
      icon: 'person',
    },
    {
      title: 'Balances',
      url: '/balances',
      icon: 'card',
    },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'mail',
    },
    // {
    //   title: 'Log Out',
    //   url: '/logout',
    //   icon: 'exit'
    // },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  private displayName = '';
  public user;
  private loggedIn = false;
  userBubbleInitials: any;
  userBubbleColorArray = ['#b47629', '#0F3D51'];
  toggle = true;
  walletId;
  currentUserBalance: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public dataService: DataServiceService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private global: GlobalFunctionsService,
    public menuCtrl: MenuController,
    private dsiBackendService: DsiBackendService
  ) {
    this.initializeApp();
    this.router.events.subscribe((e: any) => {
      this.user = this.userService.getUser();
      if (this.user) {
        this.walletId = this.user.walletID;
        if (this.dsiBackendService.walletToken == '') {
          this.dsiBackendService.setToken(this.user.token);
        }
      }
    });
  }

  checkUrl(url) {
    return this.router.url.includes(url);
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.global.logger('ionViewWillEnter APP COMPONENT');
  }

  ionViewDidEnter() {
    this.global.logger('ionViewWillEnter');
  }

  userBubble() {
    const userName: any = this.user;
    const userArray = [userName.name, userName.surname];
    this.userBubbleInitials = userArray.length > 1 ? userArray[0][0] + userArray[1][0] : userArray[0][0] + userArray[0][1];
    const assignedColour = this.userBubbleColorArray[userName.name.length % this.userBubbleColorArray.length];

    return this.userBubbleInitials;
  }

  // getBalance(userWalletId) {
  //   const data = ` {
  //     "walletID":"${userWalletId}",
  //     "balance_type":"2"
  //   }`;
  //   this.dsiBackendService.getWalletApiCall(`getBalance`, `${data}`).then((data: any) => {
  //     this.global.logger('Wallet call made to get current balance')
  //     this.global.logger('Wallet call made to get current balance', data)
  //     this.global.logger('balance', data.balance)
  //     this.currentUserBalance = data.data.balance
  //   })
  // }
  logout() {
    this.userService.login(null);
    this.router.navigate(['/']);
  }
  toggleMenu() {
    this.menuCtrl.toggle(); //Add this method to your button click function
    this.toggle = !this.toggle;
  }
}
